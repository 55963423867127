<template>
  <el-container class="page">
    <el-header class="head">
      <div class="left head-left">
        <div class="logo" v-if="logo">
          <img :src="logo" />
        </div>
        <div class="mylogo" v-else></div>
        <h2 class="title">{{ title !== "null" ? title : "新能源云集控" }}</h2>
      </div>
      <div class="head-nav">
        <meun></meun>
      </div>
      <div class="right head-right">
        <div>
          <i class="icon icon-user"></i>
          <span style="margin-left: 0.1rem">{{ sysUserName }}</span>
        </div>
        <div class="logout" @click="logout">
          <i class="icon icon-logout"></i>
        </div>
      </div>
    </el-header>
    <el-main class="page-main">
      <div class="page-main-header">
        <meun-tab></meun-tab>
      </div>
      <keep-alive :include="includeCom" :exclude="cancelArr" :max="10">
        <router-view class="main-box" />
      </keep-alive>
    </el-main>
    <el-footer class="footer">
      <footer-alarm></footer-alarm>
      <div class="safe-day">
        累计安全运行 <span class="highlight">{{ day }}</span> 天
      </div>
    </el-footer>
  </el-container>
</template>
<script>
import Meun from "./meun.vue";
import MeunTab from "./meunTab.vue";
import { mapState, mapActions } from "vuex";
import FooterAlarm from "../views/faultAlarm/FooterAlarm.vue";
import DevicePixelRatio from "../utils/devicePixelRatio.js";
export default {
  components: { Meun, MeunTab, FooterAlarm },
  props: {
    msg: String,
  },
  data() {
    return {
      title: "新能源云集控",
      sysUserName: "管理员",
      admin: 1,
      menuList: [],
      day: 0,
      tabsKeep: [],
      logo: null,
    };
  },
  computed: {
    ...mapState("navtab", ["tabs", "includeCom", "cancelArr"]),
  },
  created() {
    let that = this;
    window.addEventListener("setItemEvent", function (e) {
      if (e.key == "title") {
        that.title = e.newValue ? e.newValue : "新能源云集控";
      }
      if (e.key == "logo") {
        let src = e.newValue ? e.newValue : "null";
        that.logo = src == "null" ? null : "data:image/png;base64," + src;
      }
    });
    this.admin = sessionStorage.getItem("admin");
    this.sysUserName = sessionStorage.getItem("username");
    this.menuList = JSON.parse(sessionStorage.getItem("menuList"));
    // 刷新时以当前路由做为tab加入tabs
    this.clickMenuItem(this.$route.path);
    // new DevicePixelRatio().init();
  },
  mounted() {
    this.title = localStorage.getItem("title");
    let src = localStorage.getItem("logo");
    this.logo = src == "null" ? null : "data:image/png;base64," + src;
    this.getSafeTimeDays();
  },
  watch: {
    $route(to) {
      this.clickMenuItem(to.path);
    },
  },
  methods: {
    ...mapActions("navtab", ["clickMenuItem"]),
    async logout(data) {
      try {
        let res = await this.$api.login.logout(data);
        sessionStorage.removeItem("menuList");
        sessionStorage.removeItem("admin");
        sessionStorage.removeItem("token");
        localStorage.removeItem("currentTab");
        localStorage.removeItem("tabs");
      } catch (error) {}
      this.$router.push("/");
    },
    getSafeTimeDays() {
      this.$api.monitor
        .getSafeTimeDays()
        .then((res) => {
          if (res.code == 20000) {
            this.day = Number(res.data);
          }
        })
        .catch((error) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  position: relative;
  height: 100%;
  background-image: url("@/assets/images/home-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .head {
    background-color: #0c235c;
    color: #fff;
    font-size: 0.2rem;
    height: 0.6rem !important;
    cursor: default;
    .title {
      font-size: 0.24rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      text-transform: uppercase;
    }
    .head-left,
    .head-right {
      display: flex;
      align-items: center;
      line-height: 0.6rem;
      height: 0.6rem;
    }
    .mylogo {
      display: inline-block;
      height: 0.6rem;
      width: 0.48rem;
      line-height: 0.48rem;
      margin-right: 0.2rem;
      background-image: url("../assets/images/logo.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.4rem;
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.6rem;
      width: 0.48rem;
      line-height: 0.48rem;
      margin-right: 0.2rem;
    }
    .head-right {
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.5rem;
        .icon {
          display: inline-block;
          width: 0.26rem;
          height: 0.6rem;
        }
        .icon-user {
          background-image: url("../assets/images/admin.png");
          background-position: center 60%;
          background-size: 0.26rem;
          background-repeat: no-repeat;
        }
        .icon-logout {
          cursor: pointer;
          background-image: url("../assets/images/logout.png");
          background-position: right 60%;
          background-size: 0.26rem;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .page-main {
    position: relative;
    height: calc(100% - 0.6rem);
    .page-main-header {
      height: 0.32rem;
    }
    .slice {
      position: absolute;
      top: 0.1rem;
      right: 0.1rem;
      width: 0.32rem;
      height: 0.32rem;
      cursor: pointer;
      font-size: 0.2rem;
      color: #4798ff;
    }
    .main-box {
      position: relative;
      height: calc(100% - 0.42rem);
      font-size: 0.16rem;
      border-top: 1px solid #11386d;
      background-color: rgb(1, 16, 39, 0.8);
    }
  }
  .footer {
    background-color: #071538;
    text-align: center;
    color: #fff;
    padding: 0px;
    letter-spacing: 0.02rem;
    font-size: 0.16rem;
    height: 0.5rem !important;
    display: flex;
    justify-content: space-between;
    .safe-day {
      padding: 0.1rem 0.5rem;
      height: 0.5rem;
    }
    .highlight {
      color: #fff;
      font-size: 0.2rem;
      font-weight: 600;
    }
  }
}
</style>
    