import axios from 'axios' // 引入axios
import router from '@/router'
import qs from 'qs';
import {
    Loading,
    Message
} from 'element-ui';

const NETWORK_ERROR = '网络请求异常，请稍后重试';

const request = axios.create({
    baseURL: '/api',
    timeout: 50000
})
const loading = {
    loadingInstance: null,
    open: function() {
        if (this.loadingInstance === null) {
            this.loadingInstance = Loading.service({
                text: "loading",
                target: 'main',
                background: 'rgba(16,41,82,0.5)'
            })
        }
    },
    close: function() {
        if (this.loadingInstance !== null) {
            this.loadingInstance.close()
        }
        this.loadingInstance = null
    }
}

let count = 0
const startLoading = () => {
    if (count === 0) {
        loading.open()
    }
    count++
}
const stopLoading = () => {
        if (count <= 0) return;
        count--;
        if (count === 0) {
            loading.close()
        }
    }
    //请求拦截，添加验证token 
request.interceptors.request.use(
        config => {
            if (Number(sessionStorage.getItem('admin')) !== 3) {
                startLoading()
            }
            if (sessionStorage.getItem('token')) {
                config.headers.token = sessionStorage.getItem('token')
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    )
    // 返回token无效处理（response 拦截器）
request.interceptors.response.use(response => { //对响应数据做些事
    let ERROR = [20000, 200, 40100, 40000]
    let ERROR_CODE = [40001, 40010, 40011, 70001, 70002]
    let NETWORK_ERROR_CODE = [50000, 50001, 50002, 50003]
    const res = response.data;
    if (Number(sessionStorage.getItem('admin')) !== 3 || sessionStorage.getItem('admin') == null) {
        stopLoading()
    }
    if (response.request.responseType == 'blob') { //文件上传及下载
        return response
    } else if (!res.code) { //判断返回数据是否存在状态code和错误提示信息..
        return Promise.reject(res);
    } else if (res.code == 20000 || res.code == 200) { //成功
        return res
    } else {
        if (ERROR_CODE.includes(res.code)) { //账号问题
            sessionStorage.clear()
            router.push('/');
            return Promise.reject(res.msg || res.message);
        } else if (NETWORK_ERROR_CODE.includes(res.code)) { //网络及数据连接问题
            Message.error(res.msg || res.message || NETWORK_ERROR);
            return Promise.reject(res.msg || res.message);
        } else {
            Message.error(res.msg || res.message || NETWORK_ERROR);
            return Promise.reject(res.msg || res.message);
        }
    }
}, err => {
    stopLoading()
    return Promise.reject(err);
})

//封装get方法
export function get(url, params) {
    return new Promise((resolve, reject) => {
        request.get(url, {
            params: params
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
export function get_old(url, params) {
    return new Promise((resolve, reject) => {
        request.get(url, {
            params: params,
            responseType: 'blob',
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

//封装post方法
export function post_old(url, params) {
    params = qs.stringify(params)
    return new Promise((resolve, reject) => {
        request.post(url, params).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}
//封装post json方法
export function post(url, params) {
    return new Promise((resolve, reject) => {
        request.post(url, params, {
            headers: {
                'Content-Type': 'application/json;charset=utf-8;'
            }
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

//封装post方法 from-data格式
export function postExport(url, params) {
    params = qs.stringify(params)
    return new Promise((resolve, reject) => {
        request({
            url,
            method: 'post',
            data: params,
            responseType: 'blob',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset-utf-8"
            }
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


//封装post方法 raw格式
export function postExportFile(url, params) {
    return new Promise((resolve, reject) => {
        request({
            url,
            method: 'post',
            data: params,
            responseType: 'blob',
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}


export function Delete(url, params) {
    return new Promise((resolve, reject) => {
        request.delete(url, {
            data: params,
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export function Delete_old(url, params) {
    return new Promise((resolve, reject) => {
        request({
            url,
            method: 'delete',
            params: params,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset-utf-8"
            }
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}