<template>
  <el-container class="page back-page">
    <el-header class="head back-head">
      <div class="left head-left">
        <div class="logo" v-if="logo">
          <img :src="logo" />
        </div>
        <div class="mylogo" v-else></div>
        <h2 class="title">{{ title !== "null" ? title : "新能源云集控" }}</h2>
      </div>
      <div class="right head-right">
        <div>
          <i class="icon icon-user"></i>
          <span style="margin-left: 0.1rem">{{ sysUserName }}</span>
        </div>
        <div class="logout" @click="logout">
          <i class="icon icon-logout"></i>
        </div>
      </div>
    </el-header>
    <el-container class="page-main">
      <el-aside class="left-nav">
        <Nav :type="'vertical'" :menuList="menuList"></Nav>
      </el-aside>
      <el-container style="height: 100%">
        <el-main style="height: 100%">
          <transition name="fade" mode="out-in"><router-view /></transition>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
import Nav from "./nav.vue";
export default {
  components: { Nav },
  props: {
    msg: String,
  },
  data() {
    return {
      title: null,
      logo: null,
      sysUserName: "管理员",
      search: "",
      type: "vertical", //vertical 、horizontal
      admin: 0,
      menuList: [],
      bgColor: "",
    };
  },
  created() {
    let that = this;
    window.addEventListener("setItemEvent", function (e) {
      if (e.key == "title") {
        that.title = e.newValue ? e.newValue : "新能源云集控";
      }
      if (e.key == "logo") {
        let src = e.newValue ? e.newValue : "null";
        that.logo = src == "null" ? null : "data:image/png;base64," + src;
      }
    });
    this.admin = sessionStorage.getItem("admin");
    this.sysUserName = sessionStorage.getItem("username");
    this.menuList = JSON.parse(sessionStorage.getItem("menuList"));
  },
  mounted() {
    this.title = localStorage.getItem("title");
    let src = localStorage.getItem("logo");
    this.logo = src == "null" ? null : "data:image/png;base64," + src;
  },
  methods: {
    async logout(data) {
      try {
        let res = await this.$api.login.logout(data);
        sessionStorage.removeItem("menuList");
        sessionStorage.removeItem("admin");
        sessionStorage.removeItem("token");
        localStorage.removeItem("currentTab");
        localStorage.removeItem("tabs");
      } catch (error) {}
      this.$router.push("/");
    },
    handleSelect(key, keyPath) {
      this.activeNav = key;
      if (this.$route.path != key) {
        this.$router.push(key);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.back-page {
  background-color: #030e27;
}
.show-page {
  background-image: url("@/assets/images/home-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.home-footer {
  background-color: #071538;
}
.page {
  position: relative;
  height: 100%;
  .back-head {
    background-color: #03294d;
  }
  .show-head {
    background-color: #0c235c;
  }
  .head {
    // background-color: #03294D;
    color: #fff;
    font-size: 0.14rem;
    height: 0.6rem !important;
    .head-left,
    .head-right {
      display: flex;
      align-items: center;
      line-height: 0.6rem;
      height: 0.6rem;
    }
    .mylogo {
      display: inline-block;
      height: 0.6rem;
      width: 0.48rem;
      line-height: 0.48rem;
      margin-right: 0.2rem;
      background-image: url("../assets/images/logo.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0.4rem;
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.6rem;
      width: 0.48rem;
      line-height: 0.48rem;
      margin-right: 0.2rem;
    }
    .head-right {
      // width:3.5rem ;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.2rem;
        .icon {
          display: inline-block;
          width: 0.26rem;
          height: 0.26rem;
        }
        .icon-message {
          background-image: url("../assets/images/messge.png");
          background-position: center;
          background-size: 0.26rem;
          background-repeat: no-repeat;
        }
        .icon-user {
          background-image: url("../assets/images/admin.png");
          background-position: center;
          background-size: 0.2rem;
          background-repeat: no-repeat;
        }
        .icon-logout {
          background-image: url("../assets/images/logout.png");
          background-position: right;
          background-size: 0.2rem;
          background-repeat: no-repeat;
        }
      }
      .head-search {
        width: 1.8rem;
      }
    }
  }
  .page-main {
    height: calc(100% - 0.72rem);
  }
  .footer {
    text-align: center;
    color: #fff;
    padding: 0px;
    letter-spacing: 0.02rem;
    font-size: 0.16rem;
    line-height: 0.6rem;
    height: 0.6rem !important;
  }
}
</style>